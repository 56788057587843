import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import { Auth, Storage } from 'aws-amplify'
import awsconfig from '../aws-exports'

Vue.mixin({
  methods: {
    // Show a toast notification
    showAlert(title, type = 'primary', text = null) {
      const icons = {
        warning: 'AlertTriangleIcon',
        success: 'CheckCircleIcon',
        danger: 'XCircleIcon',
        info: 'InfoIcon',
        primary: 'InfoIcon',
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: icons[type],
          variant: type,
          text,
        },
      })
    },
    // Add https:// to the link if it doesn't have it
    prependHttps(link) {
      if (!link) {
        return ''
      }
      if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
        return link
      }
      return `https://${link}`
    },
    // Compare two objects and create a list of updates, additions and deletions by id
    listObjectChanges(oldObj, newObj, idKey = 'id') {
      // Items without idKey are considered new
      const added = newObj.filter(item => !oldObj.find(i => i[idKey] === item[idKey]))
      // Items not in the new list are considered deleted
      const deleted = oldObj.filter(item => !newObj.find(i => i[idKey] === item[idKey]))
      // Items with the same idKey are compared
      const updated = []

      newObj.forEach(item => {
        // Find object with same idKey
        const oldItem = oldObj.find(i => i[idKey] === item[idKey])
        if (!oldItem) {
          return
        }
        // Compare the two objects
        const changes = Object.keys(item).filter(key => item[key] !== oldItem[key])
        // If there are changes, add the item to the updated list
        if (changes.length > 0) {
          updated.push(item)
        }
      })

      return { updated, added, deleted }
    },
    // Return incubator domain (as in DB)
    getIncubatorFromSubdomain() {
      const urlPrefix = window.location.host.split('.')[0].toLowerCase()
      // If URL is an IP address, return null
      if (!urlPrefix.match(/^[a-z0-9]+$/i)) {
        return null
      }
      //   If URL urlPrefix is localhost, starworks, test, demo, return null
      if (['localhost', 'starworks', 'test', 'develop', 'prod-live', 'demo'].includes(urlPrefix)) {
        return null
      }
      return urlPrefix
    },

    createNotification({
      templateName,
      variables = {},
      linkRouteParams = {},
      fromOrgId,
      fromUserId,
      toOrgId,
      toUserId,
    }) {
      this.$apollo.mutate({
        mutation: gql`
          mutation createNotification($template_name: String!, $variables: jsonb, $link_route_params: jsonb, $from_org_id: Int, $from_user_id: Int, $to_org_id: Int, $to_user_id: Int) {
            insert_notifications_log_table_one(object: {notifications_template_name: $template_name, variables: $variables, link_route_params: $link_route_params, from_org_id: $from_org_id, from_user_id: $from_user_id, to_org_id: $to_org_id, to_user_id: $to_user_id}) {
              notifications_log_id
            }
          }
        `,
        variables: {
          template_name: templateName,
          variables,
          link_route_params: linkRouteParams,
          from_org_id: fromOrgId,
          from_user_id: fromUserId,
          to_org_id: toOrgId,
          to_user_id: toUserId,
        },
      })
    },
    /**
     * Workaround Explanation:
     *
     * When a page reload or refresh occurs, the user session is reset.
     * This behavior prevents the application from automatically fetching
     * the current credentials, which are necessary for maintaining
     * user authentication and state.
     *
     * To address this issue, we explicitly remove the existing credentials
     * from the session storage. This ensures that the application can
     * fetch fresh credentials upon the next load, thus maintaining
     * a seamless user experience.
     *
     * Note: This workaround is necessary until a more robust solution
     * is implemented to handle session persistence across page reloads.
     */
    async checkOrRefreshCredentials() {
      const awsIdPool = awsconfig.aws_cognito_identity_pool_id
      const currentCredentials = await Auth.currentCredentials()
      if (currentCredentials?.message?.includes('Access to Identity') && currentCredentials?.message?.includes('is forbidden')) {
        window.localStorage.removeItem(`CognitoIdentityId-${awsIdPool}`)
        await Auth.currentCredentials()
      }
    },
    /**
     * Uploads a file to AWS S3 storage.
     *
     * @async
     * @function uploadFile
     * @param {File} file - The file to be uploaded.
     * @param {string} [_level='public'] - The access level for the file. Defaults to 'public'.
     * @returns {Promise<string>} - The key of the uploaded file in S3.
     * @throws {Error} - Throws an error if no file is provided or if there is an error during upload.
     */
    async uploadFile(file, _level = 'public') {
      if (!file) throw new Error('No file provided')
      await this.checkOrRefreshCredentials()
      try {
        const sanitizedFileName = file.name.replace(/[^a-z0-9.-]/gi, '_')
        return (await Storage.put(`${Date.now()}-${sanitizedFileName}`, file, {
          level: _level,
          contentType: file.type,
        })).key
      } catch (error) {
        throw new Error('Error uploading file')
      }
    },
    /**
     * Retrieves a file link from AWS S3 storage.
     *
     * @async
     * @function getLinkFromS3
     * @param {string} key - The key of the file in S3.
     * @param {string} [level='public'] - The access level for the file. Defaults to 'public'.
     * @returns {Promise<string>} - The URL of the file in S3.
     * @throws {Error} - Throws an error if there is an issue retrieving the file link.
     */
    async getLinkFromS3(key, level = 'public') {
      await this.checkOrRefreshCredentials()
      return Storage.get(key, { level })
    },
  },
})
