export default [
  {
    path: '/startup-portal-program',
    name: 'startup-portal-program',
    component: () => import('@/views/startup-portal/pages/dashboard/index.vue'),
    meta: {
      pageTitle: 'Startup Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal-events',
    name: 'startup-portal-events',
    component: () => import('@/views/startup-portal/pages/dashboard/EventDashboard.vue'),
    meta: {
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal-competition',
    name: 'startup-portal-competition',
    component: () => import('@/views/startup-portal/pages/dashboard/CompetitionDashboard.vue'),
    meta: {
      pageTitle: 'Competitions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          active: true,
        },
      ],
    },
  },
]
