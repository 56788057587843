import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'
import { Auth } from 'aws-amplify'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloLink, split } from 'apollo-link'

Vue.use(VueApollo)

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || (() => { throw new Error('VUE_APP_GRAPHQL_HTTP is not defined') })()
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || (() => { throw new Error('VUE_APP_GRAPHQL_WS is not defined') })()

let authorizationHeader = {}

// Function to fetch the token
const setAuthorizationHeader = async () => {
  try {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()
    if (token) {
      authorizationHeader = { Authorization: `Bearer ${token}` }
    } else {
      authorizationHeader = {} // Clear the header if no token
    }
  } catch (error) {
    authorizationHeader = {} // Clear the header if token fetch fails
  }
}

// Initialize the Apollo client links
const authLink = setContext(async (_, { headers }) => {
  await setAuthorizationHeader()
  return {
    headers: {
      ...headers,
      ...authorizationHeader,
    },
  }
})

const httpLink = new BatchHttpLink({ uri: httpEndpoint })

const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    inactivityTimeout: 30000,
    connectionParams: async () => {
      await setAuthorizationHeader()
      return {
        headers: { ...authorizationHeader },
      }
    },
  },
})

const link = ApolloLink.from([
  authLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  ),
])

const defaultOptions = {
  link,
  cache: new InMemoryCache({ addTypename: false }),
}

// Apollo client creation
export const newApolloClient = createApolloClient({ ...defaultOptions }).apolloClient

export function createProvider(options = {}) {
  const { apolloClient, wsClient } = createApolloClient({ ...defaultOptions, ...options })
  apolloClient.wsClient = wsClient

  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: { $query: {} },
    errorHandler: error => error,
  })
}
