export default [
  {
    path: '/mentor-portal/events',
    name: 'mentor-events',
    component: () => import('@/views/mentor-portal/events/Events.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/rounds/:id/:pid',
    name: 'mentor-events-rounds',
    component: () => import('@/views/mentor-portal/opportunities/events/Rounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Event Application',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/rounds/details/:id/:pid/:sid',
    name: 'mentor-events-rounds-details',
    component: () => import('@/views/mentor-portal/opportunities/events/RoundDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Round details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Event Application',
        },
        {
          text: 'Event Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/my-events',
    name: 'mentor-my-events',
    component: () => import('@/views/mentor-portal/opportunities/events/mentors/AllEvents.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Events',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/participants/events',
    name: 'mentor-events',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/Events.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Events',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Upcoming Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/participants/events-registration',
    name: 'mentor-events-registration',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/EventsRegistration.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open for Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Open for Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/participants/events-applications',
    name: 'mentor-events-applications',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/EventsApplication.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Applications',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/application/:pid/:apid/:aid?',
    name: 'mentor-event-open-question',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/EventsApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/applications/rounds/:id/:aid?/:sid?',
    name: 'mentor-application-round',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/EventsRounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/event/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'mentor-event-view-evaluation',
    component: () => import('@/views/mentor-portal/opportunities/events/participants/EventViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/enter/:id/:pid/:sid',
    name: 'mentor-events',
    component: () => import('@/views/mentor-portal/opportunities/events/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/select/:id/:pid', // pid = mentor id
    name: 'mentor-events-select',
    component: () => import('@/views/mentor-portal/opportunities/events/enter/SelectOrganisation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Select Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/select/:id',
    name: 'mentor-participants-event-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectEvent.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Upcoming Events',
        },
        {
          text: 'Select Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/enter/:id/:aid',
    name: 'mentor-participanst-events',
    component: () => import('@/views/mentor-portal/opportunities/events/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'My Upcoming Events',
        },
        {
          text: 'Event',
          active: true,
        },
      ],
    },
  },
]
