export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/v1/pages/index.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/requests',
    name: 'startup-requests',
    component: () => import('@/views/startup-portal/pages/ManageRequests.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/startup-portal/resources',
  //   name: 'startup-resources',
  //   component: () => import('@/views/startup-portal/pages/ManageResources.vue'),
  //   meta: {
  //     resource: 'Dashboard',
  //     action: 'read',
  //     pageTitle: 'Resources',
  //     breadcrumb: [
  //       {
  //         text: 'Resources',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/startup-portal/library',
    name: 'startup-library-module-details',
    component: () => import('@/views/startup-portal/pages/library/ManageLibraryModules.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Library Module Details',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/library/:lid/:mname',
    name: 'startup-library-details',
    component: () => import('@/views/startup-portal/pages/library/ManageLibrary.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Library Details',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/mentors',
    name: 'startup-mentors',
    component: () => import('@/views/startup-portal/pages/ManageMentors.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Mentors',
      breadcrumb: [
        {
          text: 'Mentors',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/sessions',
    name: 'startup-sessions',
    component: () => import('@/views/startup-portal/pages/ManageSessions.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Sessions',
      breadcrumb: [
        {
          text: 'Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/partner-services',
    name: 'startup-partner-services',
    component: () => import('@/views/startup-portal/pages/ManagePartnerServices.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Partner Services',
      breadcrumb: [
        {
          text: 'Partner Services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/startup-manager',
    name: 'startup-manager',
    component: () => import('@/views/startup-portal/pages/ManageStartups.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Startup Manager',
      breadcrumb: [
        {
          text: 'Startup Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/incubator-manager',
    name: 'incubator-manager',
    component: () => import('@/views/startup-portal/pages/incubators/ManageIncubators.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Incubator Manager',
      breadcrumb: [
        {
          text: 'Incubator Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/forms',
    name: 'incubator-forms',
    component: () => import('@/views/startup-portal/pages/forms/ManageForms.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Forms Details',
      breadcrumb: [
        {
          text: 'Forms',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/forms/responses/:fid/:mname/response',
    name: 'incubator-forms-your-response',
    component: () => import('@/views/startup-portal/pages/forms/ViewFormsResponse.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Response',
      breadcrumb: [
        {
          text: 'Forms',
          to: { name: 'incubator-forms' },
        },
        {
          text: 'Your Response',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/forms/responses/:fid/:mname/view-evaluation/:rid',
    name: 'incubator-forms-view-evaluation',
    component: () => import('@/views/startup-portal/pages/forms/ViewFormEvaluation.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Evaluation',
      breadcrumb: [
        {
          text: 'Forms',
          active: true,
        },
        {
          text: 'View Evaluation',
          active: true,
        },
      ],
    },
  },
]
