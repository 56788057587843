export default [
  {
    path: '/mentor-portal/competitions',
    name: 'mentor-competitions',
    component: () => import('@/views/mentor-portal/opportunities/competitions/Competitions.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Competitons',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitons',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/rounds/:id/:pid',
    name: 'mentor-competitions-rounds',
    component: () => import('@/views/mentor-portal/opportunities/competitions/Rounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Competition Application',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/rounds/details/:id/:pid/:sid',
    name: 'mentor-competitions-rounds-details',
    component: () => import('@/views/mentor-portal/opportunities/competitions/RoundDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Round details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Competition Application',
        },
        {
          text: 'Competition Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/enter/:id/:pid/:sid',
    name: 'mentor-competitions',
    component: () => import('@/views/mentor-portal/opportunities/competitions/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Competitions',
      breadcrumb: [
        {
          text: 'Competitions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/select/:id/:pid', // pid = mentor id
    name: 'mentor-competitions-select',
    component: () => import('@/views/mentor-portal/opportunities/competitions/enter/SelectOrganisation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Competition',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Select Competition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/select/:id',
    name: 'mentor-participants-event-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectEvent.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'My Upcoming Events',
        },
        {
          text: 'Select Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/applications/rounds/:id/:aid?/:sid?',
    name: 'mentor-application-round',
    component: () => import('@/views/mentor-portal/opportunities/competitions/participants/CompetitionRounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'mentor-competitions-view-evaluation',
    component: () => import('@/views/mentor-portal/opportunities/competitions/participants/CompetitionsViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competetions',
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/application/:pid/:apid/:aid?',
    name: 'mentor-event-open-question',
    component: () => import('@/views/mentor-portal/opportunities/competitions/CompetitionsApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/participants/competitions',
    name: 'mentor-competitions',
    component: () => import('@/views/mentor-portal/opportunities/competitions/participants/Competitions.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Competitions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'My Upcoming Competitions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/participants/competitions-registration',
    name: 'mentor-competitions-registration',
    component: () => import('@/views/mentor-portal/opportunities/competitions/participants/CompetitionsRegistration.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open For Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Open For Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/participants/competitions-applications',
    name: 'mentor-competitions-applications',
    component: () => import('@/views/mentor-portal/opportunities/competitions/participants/CompetitionsApplication.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Applications',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'My Applications',
          active: true,
        },
      ],
    },
  },
]
