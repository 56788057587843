export default [
  {
    path: '/mentor-portal/programs',
    name: 'mentor-programs',
    component: () => import('@/views/mentor-portal/programs/Programs.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Startup Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/my-programs',
    name: 'mentor-programs',
    component: () => import('@/views/mentor-portal/programs/AllPrograms.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'My Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/:id',
    name: 'mentor-view-programs',
    component: () => import('@/views/incubator-portal/programs/pages/common/ProgramDetails.vue'),
    meta: {
      pageTitle: 'View Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'View Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/enter/:id/:pid/:sid',
    name: 'mentor-programs',
    component: () => import('@/views/mentor-portal/programs/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program',
      breadcrumb: [
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },

  {
    path: '/mentor-portal/programs/exercise/:id/:pid/:sid',
    name: 'mentor-exercise',
    component: () => import('@/views/mentor-portal/exercises/Exercise.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Exercises',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Exercises',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/milestone/:id/:pid/:sid',
    name: 'mentor-milestone',
    component: () => import('@/views/mentor-portal/programs/enter/Milestone.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Milestone',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Milestone',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/milestone/review/:id/:pid/:sid/:mid',
    name: 'mentor-milestone-review',
    component: () => import('@/views/mentor-portal/programs/enter/MilestoneReview.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Milestone Review',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Milestone',
        },
        {
          text: 'Milestone Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/rounds/:id/:pid',
    name: 'mentor-program-rounds',
    component: () => import('@/views/mentor-portal/programs/Rounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'Program Application',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/rounds/details/:id/:pid/:sid',
    name: 'mentor-program-rounds-details',
    component: () => import('@/views/mentor-portal/programs/RoundDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Round details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'Program Application',
        },
        {
          text: 'Program Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/select/:id/:pid?/:oid?', // pid = mentor id
    name: 'mentor-program-select',
    component: () => import('@/views/mentor-portal/programs/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/select/:id/:pid/:sid', // pid = mentor id
    name: 'mentor-program-selected',
    component: () => import('@/views/mentor-portal/programs/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/select/:id/:pid?/:oid?', // pid = mentor id
    name: 'mentor-events-select',
    component: () => import('@/views/mentor-portal/events/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/select/:id/:pid/:sid/:sname', // pid = mentor id
    name: 'mentor-events-selected',
    component: () => import('@/views/mentor-portal/events/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/events/exercise/:id/:pid/:sid',
    name: 'mentor-events-exercise',
    component: () => import('@/views/mentor-portal/exercises/Exercise.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Exercises',
      breadcrumb: [
        {
          text: 'Events',
        },
        {
          text: 'Exercises',
          active: true,
        },
      ],
    },
  },

  {
    path: '/mentor-portal/competitions/select/:id/:pid/:oid?', // pid = mentor id
    name: 'mentor-competitions-select',
    component: () => import('@/views/mentor-portal/opportunities/competitions/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/select/:id/:pid/:sid/:sname', // pid = mentor id
    name: 'mentor-competitions-selected',
    component: () => import('@/views/mentor-portal/opportunities/competitions/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/competitions/select/:id/:pid/round/:rid/:sname', // pid = mentor id
    name: 'mentor-competitions-selected-round',
    component: () => import('@/views/mentor-portal/opportunities/competitions/enter/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/mentor-portal/programs/review/score/:pid/:apid/:aid/:rid',
    name: 'mentor-review-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewScore.vue'),
    meta: {
      pageTitle: 'Evaluate Response',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Review Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/mentor-portal/programs/review/evaluations/:pid/:apid/:aid/:rid',
    name: 'mentor-review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Review Evaluations',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Review Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/programs/application/:pid/:apid/:aid?',
    name: 'mentor-application-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID, RID = Reviewer/Partner ID
    path: '/mentor-portal/programs/application/score/:pid/:apid/:aid/:rid',
    name: 'mentor-application-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
        },
        {
          text: 'Program Application',
        },
        {
          text: 'Program Round Details',
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
]
