import Vue from 'vue'
import EntityDetailsModal from '@/plugins/EntityDetailsModal.vue'

const EntityModalPlugin = {
  install() {
    // eslint-disable-next-line no-param-reassign,func-names
    Vue.prototype.$showEntityModal = function (assocId) {
      const ModalConstructor = Vue.extend(EntityDetailsModal)
      const instance = new ModalConstructor({
        propsData: { visible: true, assocId },
      })

      instance.$mount()
      document.body.appendChild(instance.$el)

      instance.$watch('visible', newVal => {
        if (!newVal) {
          document.body.removeChild(instance.$el)
          instance.$destroy()
        }
      })
    }
  },
}

export default EntityModalPlugin
