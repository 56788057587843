import invitations from './invitations'
import programs from './programs'
import events from './events'
import competitions from './competition'
import startups from './startups'

const data = [...invitations, ...programs, ...events, ...competitions, ...startups,

  // List Incubator
  {
    path: '/mentor-portal/incubators',
    name: 'mentor-incubators',
    component: () => import('@/views/mentor-portal/incubators/ManageIncubators.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Mentor',
        },
        {
          text: 'Incubators',
          active: true,
        },
      ],
    },
  },
  // Incubator Manager
  {
    path: '/mentor-portal/incubator/:id/manage',
    name: 'mentor-incubator-manager',
    component: () => import('@/views/mentor-portal/incubators/incubator-manager/index.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Mentor',
        },
        {
          text: 'Incubators',
        },
        {
          text: 'Incubator Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal-requests',
    name: 'request-details-mentor',
    component: () => import('@/views/mentor-portal/ManageRequests.vue'),
    meta: {
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal-communications',
    name: 'communications-details-mentor',
    component: () => import('@/views/mentor-portal/ManageCommunications.vue'),
    meta: {
      pageTitle: 'Communications',
      breadcrumb: [
        {
          text: 'Communications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/library',
    name: 'mentor-library-module-details',
    component: () => import('@/views/mentor-portal/library/ManageLibraryModules.vue'),
    meta: {
      pageTitle: 'Library Module Details',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/library/:lid',
    name: 'mentor-library-details',
    component: () => import('@/views/mentor-portal/library/ManageLibrary.vue'),
    meta: {
      pageTitle: 'Library Details',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/forms',
    name: 'mentor-forms-module-details',
    component: () => import('@/views/mentor-portal/forms/ManageForms.vue'),
    meta: {
      pageTitle: 'Form Details',
      breadcrumb: [
        {
          text: 'Forms',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/forms/:fid/:mname/responses',
    name: 'mentors-forms-response-view',
    component: () => import('@/views/mentor-portal/forms/ManageFormResponses.vue'),
    meta: {
      pageTitle: 'Responses',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Form Responses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/forms/:fid/:mname/responses/:rid/evaluation',
    name: 'mentors-forms-response-evaluation',
    component: () => import('@/views/mentor-portal/forms/FormScoreEvaluation.vue'),
    meta: {
      pageTitle: 'Evaluation',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Form Responses',
        },
        {
          text: 'Form Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/forms/:fid/:mname/responses/:rid/preview',
    name: 'mentors-forms-response-evaluation',
    component: () => import('@/views/mentor-portal/forms/ManageFormResponsePreview.vue'),
    meta: {
      pageTitle: 'Preview',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Form Responses',
        },
        {
          text: 'Response',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/forms/:fid/:mname/preview',
    name: 'mentor-forms-preview',
    component: () => import('@/views/mentor-portal/forms/ManageFormPreview.vue'),
    meta: {
      pageTitle: 'Preview',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Form Preview',
          active: true,
        },
      ],
    },
  },
]

export default data
